import React, { useEffect, useState } from "react";
import { Progress, Dropdown, Menu, Checkbox } from "antd";
import { useParams } from "react-router-dom";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useGetProjectDetails } from "../../../Core/redux/ReactQueryHooksV3/useProjectAPI";
import ScheduleRouteSelector from "../../../ModulesV2/Schedule/Components/ScheduleRouteSelector";
import { useDispatch } from "react-redux";
import { useGetProjectPhases } from "../../../Core/redux/ReactQueryHooksV3/useScheduleAPI";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { useAppSelector } from "../../../Core/redux/hooks";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { ArrowIcon } from "../../../Core/svgV3/ArrowIcon";
import dayjs from "dayjs";

const CustomRouteButton = ({ title, isSelected, onSelect }) => (
  <button
    onClick={() => onSelect()}
    className={`flex items-center rounded px-4 py-[5px] justify-center ${
      isSelected ? "bg-white shadow" : "bg-gray-50"
    }`}
  >
    <div
      className={`text-center ${
        isSelected ? "text-gray-500" : "text-slate-700"
      }`}
    >
      {title}
    </div>
  </button>
);
const TimelineMenu = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const { data: projectDetails, isLoading: projectLoading } =
    useGetProjectDetails(projectId);
  const { data: phases, isLoading: phasesLoading } =
    useGetProjectPhases(projectId);
  const { timelineView, selectedPhases } = useAppSelector(
    (state) => state.schedule
  );
  const [selectedButton, setSelectedButton] = useState(timelineView);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [checkedPhases, setCheckedPhases] = useState([]);

  const [selectAll, setSelectAll] = useState(false);

  const handleToggleView = () => {
    const newView = timelineView != "GanttView" ? "GanttView" : "LabelView";
    dispatch(updateSchedule({ key: "timelineView", value: newView }));
    setSelectedButton(newView);
  };

  const handleSelectButton = (button) => {
    dispatch(updateSchedule({ key: "timelineView", value: button }));
    setSelectedButton(button);
  };

  useEffect(() => {
    if (selectAll) {
      const phasesToPush = phases.map((phase) => ({ ...phase, isPhase: true }));
      setCheckedPhases(phases.map((phase) => phase.projectPhaseId));
      dispatch(
        updateSchedule({
          key: "selectedPhases",
          value: phasesToPush,
        })
      );
    } else {
      setCheckedPhases([]);
      dispatch(
        updateSchedule({
          key: "selectedPhases",
          value: [],
        })
      );
    }
  }, [selectAll, phases]);
  useEffect(() => {
    if (selectedPhases.length === phases?.length) setSelectAll(true);
  }, [selectedPhases, phases]);

  const handlePhaseSelect = (projectPhaseId) => {
    if (checkedPhases.includes(projectPhaseId)) {
      setCheckedPhases(checkedPhases.filter((id) => id !== projectPhaseId));

      const filterSelectedPhases = selectedPhases
        .filter((phase) => phase.projectPhaseId !== projectPhaseId)
        .map((phase) => ({ ...phase, isPhase: true }));
      dispatch(
        updateSchedule({
          key: "selectedPhases",
          value: [...filterSelectedPhases],
        })
      );
    } else {
      setCheckedPhases([...checkedPhases, projectPhaseId]);
      const selectedPhase = phases
        .filter((phase) => phase.projectPhaseId === projectPhaseId)
        .map((phase) => ({ ...phase, isPhase: true }));
      dispatch(
        updateSchedule({
          key: "selectedPhases",
          value: [...selectedPhases, ...selectedPhase],
        })
      );
    }
    setSelectAll(phases.length === checkedPhases.length + 1);
  };

  const handleSelectAllPhases = (checked) => {
    setSelectAll(checked);
    if (checked) {
      setCheckedPhases(phases.map((phase) => phase.projectPhaseId));
      const phasesToPush = phases.map((phase) => ({ ...phase, isPhase: true }));

      dispatch(
        updateSchedule({
          key: "selectedPhases",
          value: phasesToPush,
        })
      );
    } else {
      setCheckedPhases([]);
      dispatch(
        updateSchedule({
          key: "selectedPhases",
          value: [],
        })
      );
    }
  };
  const isPhaseSelected = (projectPhaseId) => {
    const selectedPhase = selectedPhases?.find(
      (phase) => phase.projectPhaseId === projectPhaseId
    );
    if (selectedPhase) {
      return true;
    } else {
      return false;
    }
  };

  const handleStartDayClick = (task) => {
    const selector = `[data-date="${dayjs(task.startDate).format(
      "YYYY-MM-DD"
    )}"]`;
    const today = document.querySelector(selector) as HTMLElement;
    if (today) {
      today.scrollIntoView({
        inline: "start",
        block: "end",
        behavior: "smooth",
      });
      const scrollContainer = today.parentElement; // Assuming the parent element is the scrollable container
      if (scrollContainer) {
        scrollContainer.scrollTo({
          left: today.offsetLeft,
          behavior: "smooth", // Optional: Smooth scrolling animation
        });
      }
    }
  };

  const phasesDropdownMenu = (
    <Menu>
      <Menu.Item onClick={() => handleSelectAllPhases(!selectAll)}>
        <div className="flex justify-between gap-x-3 items-center">
          <div className="w-5 h-5 rounded-full flex justify-center items-center border border-slate-100 bg-slate-50">
            <div className="w-2.5 h-2.5 rounded-full bg-gray-400"></div>
          </div>
          <span className="text-gray-900 text-sm font-light flex-grow">
            All Phases
          </span>
          <Checkbox
            checked={selectAll}
            onChange={(e) => handleSelectAllPhases(e.target.checked)}
          />
        </div>
      </Menu.Item>
      {phases?.map((phase) => {
        return (
          <Menu.Item
            key={phase.projectPhaseId}
            onClick={() => handlePhaseSelect(phase.projectPhaseId)}
          >
            <div className="flex justify-between gap-x-3 items-center">
              <div className="w-5 h-5 rounded-full flex justify-center items-center border border-slate-100 bg-slate-50">
                <div
                  className="w-2.5 h-2.5 rounded-full"
                  style={{ backgroundColor: phase.color }}
                ></div>
              </div>
              <span className="text-gray-900 text-sm font-light flex-grow">
                {phase.phases} {phase.phaseIteration}
              </span>
              <span
                className={`px-2 py-0.5 ${
                  phase.isCompleted
                    ? "bg-emerald-100 text-emerald-700"
                    : phase.isOngoing
                    ? "bg-blue-100 text-blue-700"
                    : "bg-slate-200 text-gray-500"
                } rounded-2xl text-xs font-medium`}
              >
                {phase.isCompleted
                  ? "Completed"
                  : phase.isOngoing
                  ? "Active"
                  : "Upcoming"}
              </span>
              <div
                onClick={(e) => {
                  e.stopPropagation();

                  handleStartDayClick(phase);
                }}
              >
                <ArrowIcon />
              </div>
              <Checkbox
                checked={isPhaseSelected(phase.projectPhaseId)}
                onChange={() => handlePhaseSelect(phase.projectPhaseId)}
              />
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <div className="py-3 px-6 border-b bg-slate-50 border-gray-200">
      <div className="flex justify-between items-center">
        <div>
          <div className="text-black">Timeline</div>
          <p className="text-gray-500">
            View the roadmap of your entire project in a timeline.
          </p>
        </div>
        <ScheduleRouteSelector current="timeline" />
      </div>
      {!projectLoading && (
        <div className="w-full flex items-center justify-between mt-4 gap-x-3">
          <div className="text-gray-500 text-sm">Project Progress</div>
          <span className="text-primary-600">
            {Math.round(projectDetails?.projectDetails.progress || 0)}%
          </span>
          <div className="flex-grow">
            <Progress
              showInfo={false}
              percent={projectDetails?.projectDetails.progress}
              strokeColor={"#165ABF"}
              trailColor={"#EDF3F9"}
              strokeWidth={2}
              style={{
                marginBottom: "4px",
                width: timelineView === "GanttView" ? "90%" : "100%",
              }}
            />
          </div>
          <div className="flex items-center gap-1.5">
            {timelineView === "LabelView" || timelineView === "PhaseView" ? (
              <div className="flex bg-gray-50 rounded-[5px] gap-2 p-0.5">
                <CustomRouteButton
                  title="Labels"
                  isSelected={selectedButton === "LabelView"}
                  onSelect={() => handleSelectButton("LabelView")}
                />
                {projectDetails?.projectDetails.hasPhases && (
                  <CustomRouteButton
                    title="Phases"
                    isSelected={selectedButton === "PhaseView"}
                    onSelect={() => handleSelectButton("PhaseView")}
                  />
                )}
              </div>
            ) : (
              <div>
                {projectDetails?.projectDetails.hasPhases && (
                  <Dropdown
                    overlay={phasesDropdownMenu}
                    trigger={["click"]}
                    open={dropdownOpen}
                    onOpenChange={(e) => setDropdownOpen(e)}
                  >
                    <button
                      className="justify-center pl-2.5 pr-1.5 py-[5px] bg-slate-50 text-slate-700 rounded border border-slate-200 flex items-center gap-2 text-sm font-medium"
                      onClick={(e) => {
                        e.preventDefault();
                        setDropdownOpen(!dropdownOpen);
                      }}
                    >
                      Phases
                      <ChevronIcon
                        style={{
                          transform: dropdownOpen
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      />
                    </button>
                  </Dropdown>
                )}
              </div>
            )}
            <CustomButton
              text={timelineView != "GanttView" ? "Gantt View" : "Table View"}
              className="px-2.5 py-[5px] bg-slate-50 text-slate-700 rounded border border-slate-200 flex items-center gap-2"
              onClick={handleToggleView}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TimelineMenu;

import React from "react";
import CustomLayout from "../../Core/LayoutV2/CustomLayout";

const MyCalendarLayout = ({ children }) => {
  return (
    <CustomLayout
      page="my-calendar"
      title={
        <div className="flex gap-x-1.5 bg-slate-100 border border-blue-800  "></div>
      }
    >
      {/* add my calendar tour component */}
      {children}
    </CustomLayout>
  );
};

export default MyCalendarLayout;

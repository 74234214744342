import React, { useEffect, useState } from "react";
import ScheduleLayout from "../Components/ScheduleLayout";
import TaskHeader from "./TaskHeader";
import { Dropdown, message, Spin } from "antd";

import {
  AddApprovers,
  DueDate,
  LabelSelect,
  PhaseSelect,
  PrioritySelect,
  RowLayout,
  ScheduleCollaboratorSelect,
  SelectStatus,
  SprintSelect,
  TaskDueDate,
  TaskInput,
  UserSelect,
  VersionSelect,
} from "./TaskReusable";
import TimeInput from "./TimeInput";

import CommentsAndActivity from "./CommentsAndActivity";
import InfoByFrosty from "../../../Core/CommonV2/InfoByFrosty";
import NewLabelModal from "../Modals/NewLabelModal";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  resetSchedule,
  updateSchedule,
} from "../../../Core/redux/slices/schedule.slice";
import ManageLabelsModal from "../Modals/ManageLabelsModal";
import DeleteLabelModal from "../Modals/DeleteLabelModal";
import NewReleaseVersionModal from "../Modals/NewReleaseVersionModal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  createGoal,
  getGoalDetails,
  getGoalsBySprint,
  getLabels,
  getReleaseVersions,
  getSprintsByPhase,
  getTaskActivityLog,
  getTodoList,
  updateGoal,
  updateParentTaskTimeLog,
  updateProgress,
} from "../../../Core/redux/api/scheduleAPI";
import AttachmentsAndSubtask from "./AttachmentsAndSubtask";
import { client } from "../../../Core/utils/axiosClient";
import { getComments } from "../../../Core/redux/api/conversationAPI";
import {
  DEFAULT_LIMIT,
  TASK_ACTIVITY_LOG_ACTIONS,
} from "../../../Core/constants";
import usePermission from "../../../Core/hooks/usePermission";
import { DotsVerticalIcon } from "../../../Core/svgV2/DotsVertical";
import { CopyIcon, CopyLinkIcon } from "../../../Core/svgV2/CopyIcon";
import { DeleteIcon } from "../../../Core/svgV2/DeleteIcon";
import copyTextToClipboard from "../../../Core/utils/clipboard";
import DeleteTaskModal from "../Modals/DeleteTaskModal";
import ChangeAssigneeModal from "../Modals/ChangeAssigneeModal";

import FilePreviweModal from "../../Conversations/Modals/FIlePreviweModal";
import { resetConversations } from "../../../Core/redux/slices/conversations.slice";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import { useQueryClient } from "react-query";
import dayjs from "dayjs";
import DiscardEditedGoalModal from "./DiscardEditedGoalModal";
import {
  useGetProjectDetails,
  useGetProjectList,
} from "../../../Core/redux/ReactQueryHooksV3/useProjectAPI";
import SubtaskToDoneModal from "../../Settings/Modals/SubtaskToDoneModal";

const TaskScreen = () => {
  const { user } = useAppSelector((state) => state.userDetails);
  const { projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { filePreview } = useAppSelector((state) => state.conversations);
  const [discardModal, setDiscardModal] = useState(false);
  const [subtasksInProgressModal, setSubtasksInProgressModal] = useState(false);
  const [moveSubtasks, setMoveSubtasks] = useState(false);
  const [subtaskEdits, setSubtaskEdits] = useState(null);
  const { data: projectList, isLoading: isLoadingProjectList } =
    useGetProjectList(user.id);

  const { automations } = useAppSelector((state) => state.settings);
  const [taskDeleteModal, setTaskDeleteModal] = useState(false);
  const [completedOn, setCompletedOn] = useState("");

  const navigate = useNavigate();
  const {
    newLabelModal,
    manageLabelModal,
    deleteLabelModal,
    newReleaseVersionModal,
    selectedTask,
    phases,
    sprints,
    newTask,
    selectedTasks,
    collaboratorsForTask,
    onAssigneeChange,
    isBug,
    subtasksInProgressWarning,
    swimlanes,
    taskActivity,
  } = useAppSelector((state) => state.schedule);
  const dispatch = useAppDispatch();
  const { taskId } = useParams();

  const [taskType, setTaskType] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isWaddleIt, setIsWaddleIt] = useState(false);
  const queryClient: any = useQueryClient();

  const handleClose = async () => {
    console.log("im here 1");
    const currentUrl = localStorage.getItem("taskRedirectUrl");
    if (taskDetails?.sprintId) {
      await queryClient.invalidateQueries([
        "goalsBySprint",
        projectId,
        +taskDetails?.sprintId,
      ]);
    } else {
      await dispatch(
        getGoalsBySprint({
          projectId,
          module: "backlog",
        })
      );
    }
    dispatch(updateSchedule({ key: "isTaskExpanded", value: false }));
    // navigate(-1);
    let redirectToLink = "";
    if (projectList?.length > 0) {
      const project = projectList?.find(
        (project) => +project.id === +projectId
      );
      redirectToLink = `/schedule/${project?.id}/sprints/${taskDetails?.sprintId}`;
    }

    taskId === "new"
      ? navigate(-1)
      : selectedTask?.sprintId
      ? redirectToLink
        ? navigate(redirectToLink)
        : navigate(-1)
      : navigate(`/schedule/${projectId}/backlog`);
  };

  const getName = () => {
    switch (selectedTask?.goalType) {
      case "1":
        return "task";
      case "3":
        return "bug";
      default:
        return "task";
    }
  };

  useEffect(() => {
    dispatch(getLabels({ projectId, isMyBoard: false }));
    dispatch(getReleaseVersions({ projectId }));
    dispatch(getSprintsByPhase({ projectId }));
  }, [projectId]);

  const hasSubtasksWithStatusNotThree = selectedTask?.subtasks?.some(
    (subtask) => {
      return subtask.statusId !== 3;
    }
  );

  let statusIndicator = null;

  if (hasSubtasksWithStatusNotThree) {
    const hasStatus1 = selectedTask.subtasks.some(
      (subtask) => subtask.statusId === 1
    );
    const hasStatus2 = selectedTask.subtasks.some(
      (subtask) => subtask.statusId === 2
    );

    if (hasStatus1 && hasStatus2) {
      statusIndicator = 3; // Both status 1 and 2 are present
    } else if (hasStatus1) {
      statusIndicator = 1; // Only status 1 is present
    } else if (hasStatus2) {
      statusIndicator = 2; // Only status 2 is present
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (taskId !== "new") {
        setLoading(true); // Set loading to true before dispatching

        try {
          await dispatch(getGoalDetails({ goalId: taskId }));

          if (+selectedTask?.goalType === 3) {
            dispatch(
              updateSchedule({
                key: "isBug",
                value: true,
              })
            );
          } else {
            dispatch(
              updateSchedule({
                key: "isBug",
                value: false,
              })
            );
          }

          dispatch(
            getComments({
              docId: taskId,
              docType: "task",
              limit: 1000,
              offset: 0,
            })
          );

          dispatch(getTaskActivityLog({ goalId: taskId }));
          dispatch(updateSchedule({ key: "isTaskExpanded", value: true }));
        } finally {
          setLoading(false); // Set loading to false after all dispatches are done
        }
      }
    };

    fetchData();
  }, [taskId]);
  // useEffect(() => {
  //   if (taskId !== "new") {
  //     dispatch(getGoalDetails({ goalId: taskId }));
  //   }
  // }, [selectedTask?.goalType]);

  const updateSprintGoals = async (taskDetails) => {
    if (taskDetails.sprintId) {
      await dispatch(
        getGoalsBySprint({
          projectId,
          sprintId: taskDetails.sprintId,
          module: "sprints",
        })
      );
    } else {
      await dispatch(
        getTodoList({ projectId, limit: DEFAULT_LIMIT, offset: 0 })
      );
    }
  };

  const sprintIdFromSearchParams =
    searchParams.get("sprintId") == "null"
      ? null
      : searchParams.get("sprintId");

  const [taskDetails, setTaskDetails] = useState({
    statusId: 1,
    createdAt: null,

    swimlaneId: "",
    assigneeId: user.id,
    reporterId: null,
    phaseId: phases.find((p) => p.isOngoing == 1)?.projectPhaseId || "",
    priorityId: 1,
    labelId: "",
    estimatedTime: "",
    releaseVersionId: "",
    description: "",
    timeLogged: "",
    attachments: [],
    subtasks: [],
    dueDate: null,
    startDate: null,
    endDate: null,
    sprintId: sprintIdFromSearchParams || null,
    collaborators: [],
    removeCollaborators: [],
  });
  const [editedTaskDetails, setEditedTaskDetails] = useState(null);

  const [waddleItUsers, setWaddleItUsers] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);

  const { formState, control, setValue, handleSubmit, getValues } = useForm({
    values: {
      taskName: "",
      timeLogged: "",
    },
  });

  const [editTaskName, setEditTaskName] = useState(selectedTask?.name);

  const { data: projectDetails, isLoading: projectLoading } =
    useGetProjectDetails(projectId);

  useEffect(() => {
    if (taskId !== "new" && selectedTask) {
      setValue("taskName", selectedTask?.name);
      setEditTaskName(selectedTask?.name);
      setTaskDetails({
        ...selectedTask,
        attachments: selectedTask?.attachments
          ? JSON.parse(selectedTask?.attachments)
          : [],
        subtasks: selectedTask?.subtasks ? selectedTask.subtasks : [],
      });
      const waddleItUser =
        selectedTask.waddleIt?.map((user) => user.userId) || [];
      setWaddleItUsers(waddleItUser);
      setIsWaddleIt(waddleItUser.length > 0);
      setTaskType(+selectedTask?.goalType === 4 ? 1 : selectedTask?.goalType);
      dispatch(
        updateSchedule({
          key: "collaboratorsForTask",
          value: selectedTask?.collaborators?.map(
            (collaborator) => collaborator.id
          ),
        })
      );
      const swimlaneId = swimlanes.filter((item) => item.statusId == 3);

      if (taskActivity.length === 0 && selectedTask.statusId == 3) {
        setCompletedOn(dayjs(selectedTask.createdAt).format("DD/MM/YYYY"));
      }

      if (
        taskActivity &&
        taskActivity.length > 0 &&
        taskActivity[0].newValue == swimlaneId[0].id &&
        taskActivity[0].action == "STATUS_CHANGED"
      ) {
        if (selectedTask.id == taskActivity[0].goalId)
          setCompletedOn(dayjs(taskActivity[0].timestamp).format("DD/MM/YYYY"));
      }
    }
  }, [selectedTask]);

  useEffect(() => {
    if (newTask && taskId === "new") {
      setValue("taskName", newTask.name);
      setValue("timeLogged", newTask.timeLogged);
      setTaskType(+newTask.goalType === 4 ? 1 : newTask.goalType);

      setTaskDetails((prevValue) => ({
        ...prevValue,
        statusId:
          newTask?.taskDetails?.statusId !== null
            ? newTask?.taskDetails?.statusId
            : prevValue.statusId,

        priorityId:
          newTask?.taskDetails?.priorityId !== null
            ? newTask?.taskDetails?.priorityId
            : prevValue.priorityId,
        // sprintId:
        //   newTask?.sprintId !== null ? newTask?.sprintId : prevValue.sprintId,

        labelId:
          newTask?.taskDetails?.labelId !== null
            ? newTask?.taskDetails?.labelId
            : prevValue.labelId,
        startDate:
          newTask?.taskDetails?.startDate !== null
            ? newTask?.taskDetails?.startDate
            : prevValue.startDate,
        endDate:
          newTask?.taskDetails?.endDate !== null
            ? newTask?.taskDetails?.endDate
            : prevValue.endDate,
        collaborators:
          newTask?.taskDetails?.collaborators !== null
            ? newTask?.taskDetails?.collaborators
            : prevValue.collaborators,
      }));
    }
  }, [newTask]);

  const createTask = async (data) => {
    const uniqueCollaboratorIds = [];
    collaboratorsForTask.forEach((element) => {
      if (!uniqueCollaboratorIds.includes(element)) {
        uniqueCollaboratorIds.push(element);
      }
    });
    const payload = {
      statusId: taskDetails.statusId ? taskDetails.statusId : 1,
      sprintId: taskDetails.sprintId,
      projectId: projectId,
      phaseId: taskDetails.phaseId,
      name: data.taskName,
      isCompleted: 0,
      isNewGoal: 0,
      swimlaneId: taskDetails.swimlaneId,
      priorityId: taskDetails.priorityId ? taskDetails.priorityId : 1,
      goalType: taskType ? taskType : 1,
      assigneeId: taskDetails.assigneeId,
      reporterId: taskDetails.reporterId,
      description: taskDetails.description,
      estimatedTime: taskDetails.estimatedTime,
      timeLogged: taskDetails.timeLogged
        ? taskDetails.timeLogged
        : data.timeLoggged
        ? data.timeLogged
        : "",
      subtasks: taskDetails.subtasks,
      attachments: JSON.stringify(taskDetails.attachments),
      labelId: taskDetails.labelId,
      releaseVersionId: taskDetails.releaseVersionId,
      dueDate: taskDetails.dueDate,
      isWaddleIt,
      collaborators: collaboratorsForTask ? uniqueCollaboratorIds : [],
      waddleIt: waddleItUsers,
      startDate: taskDetails.startDate,
      endDate: taskDetails.endDate,
    };
    const getNewGoalName = () => {
      switch (taskType) {
        case 1:
          return "task";
        case 3:
          return "bug";
        case 4:
          return "subtask";
        default:
          return "task";
      }
    };
    // console.log(payload, "payload");

    const response = await dispatch(createGoal(payload));

    dispatch(
      updateDashboard({
        key: "alertPopupModal",
        value: {
          visible: true,
          data: {
            title: `New ${getNewGoalName()} created`,
            subtitle: `${data.taskName}`,
            description: "",
          },
        },
      })
    );
    dispatch(updateSchedule({ key: "selectedTask", value: payload }));
    dispatch(
      updateSchedule({
        key: "createTaskHeader",
        value: { visible: false, sprintId: null },
      })
    );
    dispatch(
      updateSchedule({
        key: "collaboratorsForTask",
        value: [],
      })
    );
    const selectedSprint = sprints?.find(
      (sprint) => +taskDetails?.sprintId == sprint.id
    );

    if (taskId !== "new") {
      navigate(
        `/schedule/${projectId}/sprints/${searchParams.get("sprintId")}`
      );
    } else {
      dispatch(
        updateSchedule({
          key: "collaboratorsForTask",
          value: [],
        })
      );
      taskDetails?.sprintId
        ? selectedSprint?.status == 2
          ? navigate(`/schedule/${projectId}/sprints/${taskDetails.sprintId}`)
          : navigate(`/schedule/${projectId}/sprints`)
        : navigate(`/schedule/${projectId}/backlog`);
    }

    dispatch(
      updateProgress({
        projectId,
      })
    );
    const parsedSprintId = parseInt(taskDetails.sprintId, 10);
    queryClient.resetQueries(["goalsBySprint", projectId, parsedSprintId], {
      inactive: true,
    });
  };

  const waitForModal = () => {
    return new Promise<void>((resolve) => {
      const checkVisibility = () => {
        if (subtasksInProgressWarning.visible) {
          resolve();
        } else {
          requestAnimationFrame(checkVisibility);
        }
      };
      checkVisibility();
    });
  };

  const editTask = async () => {
    const action = [];
    if (editedTaskDetails?.assigneeId) {
      action.push({
        oldValue: selectedTask.assigneeId,
        newValue: editedTaskDetails.assigneeId,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.ASSIGNEE_CHANGED,
      });
    }
    if (editedTaskDetails?.reporterId) {
      action.push({
        oldValue: selectedTask.reporterId,
        newValue: editedTaskDetails.reporterId,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.REPORTER_CHANGED,
      });
    }
    if (editedTaskDetails?.statusId || editedTaskDetails?.swimlaneId) {
      const inprogressSubtasks = selectedTask?.subtasks?.find(
        (subtask) => subtask.statusId !== 3
      );
      // if (inprogressSubtasks && +editedTaskDetails?.statusId === 3) {
      //   dispatch(
      //     updateSchedule({
      //       key: "subtasksInProgressWarning",
      //       value: {
      //         visible: true,
      //         goalId: taskId,
      //         statusId: editedTaskDetails?.statusId,
      //         swimlaneId: editedTaskDetails?.swimlaneId,
      //         oldSwimlaneId: selectedTask?.swimlaneId,
      //         sprintId: selectedTask?.sprintId,
      //       },
      //     })
      //   );
      //   await waitForModal();
      // } else {
      action.push({
        oldValue: selectedTask.swimlaneId,
        newValue: editedTaskDetails.swimlaneId,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.STATUS_CHANGED,
      });
      // }
    }
    if (editedTaskDetails?.phaseId) {
      action.push({
        oldValue: selectedTask.phaseId,
        newValue: editedTaskDetails.phaseId,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.PHASE_CHANGED,
      });
    }
    if (editedTaskDetails?.priorityId) {
      action.push({
        oldValue: selectedTask.priorityId,
        newValue: editedTaskDetails.priorityId,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.PRIORITY_CHANGE,
      });
    }
    if (editedTaskDetails?.labelId) {
      action.push({
        oldValue: selectedTask.labelId,
        newValue: editedTaskDetails.labelId,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.LABEL_CHANGE,
      });
    }
    if (editedTaskDetails?.sprintId) {
      action.push({
        oldValue: selectedTask.sprintId,
        newValue: editedTaskDetails.sprintId,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.SPRINT_CHANGE,
      });
    }
    if (editedTaskDetails?.estimatedTime) {
      action.push({
        oldValue: selectedTask.estimatedTime,
        newValue: editedTaskDetails.estimatedTime,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.ESTIMATE_CHANGE,
      });
    }
    if (editedTaskDetails?.timeLogged) {
      action.push({
        oldValue: selectedTask.timeLogged,
        newValue: editedTaskDetails.timeLogged,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.TIMELOG_CHANGE,
      });
    }
    if (editedTaskDetails?.releaseVersionId) {
      action.push({
        oldValue: selectedTask.releaseVersionId,
        newValue: editedTaskDetails.releaseVersionId,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.RELEASE_VERSION_CHANGE,
      });
    }
    const payload = {
      ...editedTaskDetails,
      action: [...action],
      goalId: taskId,
    };
    if (editedTaskDetails) {
      await dispatch(updateGoal(payload));
      if (moveSubtasks) {
        const oldGoals = [];
        const taskIds = [];
        const incompleteSubtasks = taskDetails.subtasks
          .filter((subtask) => subtask.statusId !== 3)
          .map((subtask) => ({ ...subtask, isSubtask: true }));
        oldGoals.push(...incompleteSubtasks);
        taskIds.push(...incompleteSubtasks.map((subtask) => subtask.id));
        await dispatch(
          updateGoal({
            goalId: taskIds,
            swimlaneId: subtaskEdits?.swimlaneId,
            statusId: 3,
            action: {
              oldValue: subtaskEdits?.oldSwimlaneId,
              newValue: subtaskEdits?.swimlaneId,
              actionName: TASK_ACTIVITY_LOG_ACTIONS.STATUS_CHANGED,
            },
          })
        );

        if (oldGoals.length) {
          for (const goal of oldGoals) {
            if (!goal.isSubtask) {
              await client.post("/notifications/create", {
                notificationType: "33",
                goalId: goal.id,
                allUsers: [
                  { userId: goal.assigneeId },
                  { userId: goal.reporterId },
                ],
                projectId: goal.projectId,
                meta: {
                  oldStatusId: goal.statusId,
                  newStatusId: subtaskEdits?.statusId,
                  goalId: goal.id,
                },
              });
            }
          }
        } else {
          await client.post("/notifications/create", {
            notificationType: "33",
            goalId: subtasksInProgressWarning?.goalId,
            allUsers: [
              { userId: oldGoals[0].assigneeId },
              { userId: oldGoals[0].reporterId },
            ],
            projectId: oldGoals[0].projectId,
            meta: {
              oldStatusId: oldGoals[0].statusId,
              newStatusId: subtaskEdits?.statusId,
              goalId: subtaskEdits?.goalId,
            },
          });
        }
      }
      if (editedTaskDetails?.assigneeId) {
        await client.post("/notifications/create", {
          notificationType: "32",
          goalId: taskId,
          allUsers: [
            { userId: taskDetails.assigneeId },
            { userId: taskDetails.reporterId },
          ],
          projectId,
          meta: {
            oldAssigneeId: selectedTask.assigneeId,
            newAssigneeId: editedTaskDetails?.assigneeId,
          },
        });
      }
      if (editedTaskDetails?.statusId || editedTaskDetails?.swimlaneId) {
        await client.post("/notifications/create", {
          notificationType: "33",
          goalId: taskId,

          allUsers: [
            { userId: taskDetails.assigneeId },
            { userId: taskDetails.reporterId },
          ],
          projectId: projectId,
          meta: {
            oldStatusId: selectedTask.statusId,
            newStatusId: editedTaskDetails.statusId,
            goalId: taskId,
          },
        });
      }
    }
    // dispatch(getGoalDetails({ goalId: taskId }));
    if (!taskDetails.sprintId) {
      await dispatch(
        getTodoList({ limit: DEFAULT_LIMIT, offset: 0, projectId })
      );
    } else {
      dispatch(
        await getGoalsBySprint({
          projectId,
          sprintId: sprintIdFromSearchParams,
        })
      );
    }
    // dispatch(getTaskActivityLog({ goalId: taskId }));

    if (editedTaskDetails && editedTaskDetails?.statusId == 3) {
      dispatch(
        updateProgress({
          projectId,
        })
      );
    }
    // setTaskDetails({ ...taskDetails, ...editedTaskDetails });
    updateSprintGoals(taskDetails);
    setEditedTaskDetails(null);
    handleClose();
  };

  const onSubmit = async (data) => {
    if (taskId === "new") {
      createTask(data);
    } else {
      editTask();
    }
  };

  const { hasPermission: TASK_EVENT_SCOPE_DELETE } = usePermission("10");

  const groupItems = [
    {
      key: "1",
      label: <div className="out-300-14 text-gray-700">Copy link</div>,
      icon: <CopyLinkIcon />,
    },

    {
      key: "2",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">Duplicate</div>
      ),
      icon: <CopyIcon className="text-gray-700" />,
    },

    TASK_EVENT_SCOPE_DELETE ? { key: "divider", type: "divider" } : null,
    TASK_EVENT_SCOPE_DELETE
      ? {
          key: "3",
          label: <div className="out-300-14 text-gray-700">Delete</div>,

          icon: <DeleteIcon className="text-gray-700 " />,
        }
      : null,
  ];
  useEffect(() => {
    if (taskId === "new") {
      dispatch(
        updateSchedule({
          key: "collaboratorsForTask",
          value: [],
        })
      );
    }
  }, [taskId]);
  // automation code
  useEffect(() => {
    if (taskId === "new") {
      // setTaskType(1);
      dispatch(
        updateSchedule({
          key: "selectedTask",
          value: null,
        })
      );
      const getReporterAutomations = automations.find(
        (a) => +a.goalType == +taskType && a.fieldName === "Reporter"
      );
      if (getReporterAutomations && getReporterAutomations.value) {
        setTaskDetails((prev) => {
          const obj = { ...prev, reporterId: getReporterAutomations.value };
          return obj;
        });
      } else {
        setTaskDetails((prev) => {
          const obj = { ...prev, reporterId: null };
          return obj;
        });
      }
      if (getValues("timeLogged")) {
        setTaskDetails((prev) => {
          const obj = { ...prev, timeLogged: getValues("timeLogged") };
          return obj;
        });
      }
      const getAssigneerAutomations = automations.find(
        (a) => a.goalType == taskType && a.fieldName === "Assignee"
      );
      if (getAssigneerAutomations && getAssigneerAutomations.value) {
        setTaskDetails((prev) => {
          const obj = { ...prev, assigneeId: getAssigneerAutomations.value };
          return obj;
        });
      } else {
        setTaskDetails((prev) => {
          const obj = { ...prev, assigneeId: user.id };
          return obj;
        });
      }
    }
  }, [taskType, taskId]);

  useEffect(() => {
    if (taskId === "new") {
      dispatch(
        updateSchedule({
          key: "isBug",
          value: false,
        })
      );
      if (isBug) {
        setTaskType(3);
      }
    }
  }, []);

  const { hasPermission: TASK_EVENT_SCOPE_EDIT } = usePermission("9");
  const { hasPermission: REPORTER_EDIT } = usePermission("20");
  const { hasPermission: LOG_TIME_EDIT } = usePermission("18");
  const { hasPermission: LOG_TIME_ADD } = usePermission("17");

  const hasEditAccess = TASK_EVENT_SCOPE_EDIT || taskId === "new";

  const handleDuplicate = async () => {
    try {
      const { data } = await client.post("/schedule/duplicate-goal", {
        id: taskId,
      });

      navigate(`/schedule/${projectId}/task/${data.result.id}`);
    } catch (e) {
      console.log(e);
    }
  };

  const onClick = ({ key }) => {
    switch (key) {
      case "1":
        copyTextToClipboard(window.location.href);
        break;
      case "2":
        handleDuplicate();
        break;
      case "3":
        dispatch(
          updateSchedule({
            key: "selectedTasks",
            value: [taskId],
          })
        );
        setTaskDeleteModal(true);
        break;
      default:
        break;
    }
  };

  useEffect(
    () => console.log(editedTaskDetails, "attachmemts edit 2"),
    [editedTaskDetails]
  );

  const handleYes = () => {
    const updatedCollaborators = taskDetails?.collaborators
      ?.filter((user) => user.id === onAssigneeChange?.originalAssignee)
      .map((u) => u.id);

    console.log(taskDetails?.collaborators, taskDetails, "wonderful");

    setEditedTaskDetails({
      ...editedTaskDetails,
      assigneeId: onAssigneeChange?.updatedAssignee,
      collaborators: onAssigneeChange?.updatedAssignee
        ? [onAssigneeChange.updatedAssignee]
        : [],
      removeCollaborators: updatedCollaborators,
    });

    dispatch(
      updateSchedule({
        key: "onAssigneeChange",
        value: { visible: false, originalAssignee: "", updatedAssignee: "" },
      })
    );
  };

  const handleNo = () => {
    setEditedTaskDetails((prevDetails) => ({
      ...prevDetails,
      assigneeId: onAssigneeChange?.updatedAssignee,
      collaborators: onAssigneeChange?.updatedAssignee
        ? [onAssigneeChange.updatedAssignee]
        : [],
    }));

    dispatch(
      updateSchedule({
        key: "onAssigneeChange",
        value: { visible: false, originalAssignee: "", updatedAssignee: "" },
      })
    );
  };

  const RevertStatus = (val) => {
    setSubtasksInProgressModal(val);
    setSubtaskEdits(null);
    setEditedTaskDetails({
      ...editedTaskDetails,
      statusId: subtaskEdits?.oldStatusId,
      swimlaneId: subtaskEdits?.oldSwimlaneId,
    });
    setTaskDetails((oldState) => {
      // const newState =
      return {
        ...oldState,
        statusId: subtaskEdits?.oldStatusId,
        swimlaneId: subtaskEdits?.oldSwimlaneId,
      };
      // return newState;
    });
  };

  const SaveSubtaskEdit = (flag) => {
    setSubtasksInProgressModal(false);
    if (flag) setMoveSubtasks(true);
    setEditedTaskDetails({
      ...editedTaskDetails,
      statusId: subtaskEdits?.statusId,
      swimlaneId: subtaskEdits?.swimlaneId,
    });
    setTaskDetails((oldState) => {
      // const newState =
      return {
        ...oldState,
        statusId: subtaskEdits?.statusId,
        swimlaneId: subtaskEdits?.swimlaneId,
      };
      // return newState;
    });
  };

  const onDiscardTask = async () => {
    await dispatch(updateSchedule({ key: "isGoalEdited", value: false }));
    handleClose();
  };

  return (
    <ScheduleLayout>
      <TaskHeader
        taskType={taskType}
        setTaskType={setTaskType}
        isWaddleIt={isWaddleIt}
        setIsWaddleIt={setIsWaddleIt}
        handleSubmit={handleSubmit(onSubmit)}
        task={taskDetails}
        onCancel={() => {
          if (
            editedTaskDetails &&
            Object.keys(editedTaskDetails).length !== 0
          ) {
            setDiscardModal(true);
          } else {
            handleClose();
          }
        }}
        isEdited={
          !!editedTaskDetails && Object.keys(editedTaskDetails).length !== 0
        }
      />
      <Spin spinning={loading}>
        <div className="flex bg-white min-h-[calc(100vh-100px)]">
          <div className="flex-1 px-6 py-4 min-h-[calc(100vh-100px)] max-h-[calc(100vh-100px)] overflow-y-auto">
            <div className="relative">
              {!TASK_EVENT_SCOPE_EDIT && (
                <div className="absolute z-[100] top-0 left-0 right-0 bottom-0"></div>
              )}
              {taskId === "new" ? (
                <Controller
                  name="taskName"
                  control={control}
                  rules={{ required: "Please enter a title before submitting" }}
                  render={({ field, formState }) => (
                    <TaskInput
                      {...field}
                      autoFocus={taskId === "new" ? true : false}
                      autoSize
                      placeholder="Enter a title"
                      showError={
                        formState.errors.taskName?.message ? true : false
                      }
                    />
                  )}
                />
              ) : (
                <TaskInput
                  autoSize
                  value={editTaskName}
                  placeholder="Enter a title"
                  onChange={async (e) => {
                    // setTaskDetails({ ...taskDetails, name: e.target.value });
                    if (!TASK_EVENT_SCOPE_EDIT) return;
                    setEditTaskName(e.target.value);
                    clearTimeout(timeoutId);

                    setEditedTaskDetails({
                      ...editedTaskDetails,
                      name: e.target.value,
                    });

                    setTimeoutId(
                      setTimeout(() => {
                        dispatch(
                          updateSchedule({ key: "isGoalEdited", value: true })
                        );
                        // dispatch(
                        //   updateGoal({
                        //     name: e.target.value,
                        //     goalId: selectedTask.id,
                        //   })
                        // );
                      }, 1000)
                    );
                  }}
                  showError={formState.errors.taskName?.message ? true : false}
                />
              )}

              {formState.errors.taskName?.message && (
                <div className="mt-1.5 out-300-12 text-left text-error-500">
                  {formState.errors.taskName?.message}
                </div>
              )}

              <div className="mt-4">
                <TaskInput
                  onChange={async (e) => {
                    if (!hasEditAccess) return;
                    setTaskDetails({
                      ...taskDetails,
                      description: e.target.value,
                    });
                    if (taskId !== "new") {
                      setEditedTaskDetails({
                        ...editedTaskDetails,
                        description: e.target.value,
                      });
                      // await dispatch(
                      //   updateGoal({
                      //     description: e.target.value,
                      //     goalId: selectedTask.id,
                      //   })
                      // );
                      await dispatch(
                        updateSchedule({ key: "isGoalEdited", value: true })
                      );
                    }
                  }}
                  autoSize={{ minRows: 3 }}
                  inputClassName="task-description"
                  placeholder="Enter a description..."
                  value={taskDetails.description}
                />
              </div>
            </div>
            <AttachmentsAndSubtask
              task={taskDetails}
              setSubtasks={async (e) => {
                setTaskDetails(e);
                // setEditedTaskDetails({
                //   ...editedTaskDetails,
                //   subtasks: e.subtasks,
                // });
              }}
              setEditedSubtaskDetails={(e) => {
                setEditedTaskDetails({
                  ...editedTaskDetails,
                  subtasks: e.subtasks,
                });
              }}
              setAttachments={async (e) => {
                setTaskDetails(e);
                setEditedTaskDetails({
                  ...editedTaskDetails,
                  attachments: e.attachments,
                });
              }}
              setEditedAttachmentDetails={(e) => {
                setEditedTaskDetails({
                  ...editedTaskDetails,
                  attachments: e.attachments,
                });
              }}
            />

            {taskId !== "new" && <CommentsAndActivity task={selectedTask} />}
          </div>
          <div className="relative px-6 w-[386px] border-l border-gray-200 min-h-[calc(100vh-100px)] max-h-[calc(100vh-100px)] overflow-y-auto pt-5 ">
            {!TASK_EVENT_SCOPE_EDIT && (
              <div className="absolute z-[100] top-0 bottom-0 left-0 right-0"></div>
            )}
            <div className="flex justify-between">
              <SelectStatus
                setStatusId={async (e) => {
                  if (!hasEditAccess) return;

                  if (taskDetails.sprintId) {
                    const currentSprint = sprints.find(
                      (sprint) => +sprint.id == +taskDetails.sprintId
                    );

                    if (currentSprint && currentSprint.status == 3) {
                      message.error({
                        content: (
                          <span className="out-400-14 text-gray-900 cursor-pointer">
                            Please Start the Sprint first!
                          </span>
                        ),
                        className: "custom-message",
                        style: {
                          borderRadius: "10px",
                        },
                      });

                      return;
                    }
                    if (currentSprint && currentSprint.status < 2) {
                      message.error({
                        content: (
                          <span className="out-400-14 text-gray-900 cursor-pointer">
                            Please Start the Sprint first!
                          </span>
                        ),
                        className: "custom-message",
                        style: {
                          borderRadius: "10px",
                        },
                      });

                      return;
                    }
                  }
                  if (
                    taskId !== "new" &&
                    e.statusId === "3" &&
                    hasSubtasksWithStatusNotThree
                  ) {
                    setSubtaskEdits({
                      statusId: e.statusId,
                      swimlaneId: e.swimlaneId,
                      oldStatusId: taskDetails.statusId,
                      oldSwimlaneId: taskDetails.swimlaneId,
                    });
                    setSubtasksInProgressModal(true);
                    return;
                  } else {
                    setSubtaskEdits(null);
                    setTaskDetails((oldState) => {
                      const newState = {
                        ...oldState,
                        statusId: e.statusId,
                        swimlaneId: e.swimlaneId,
                      };
                      return newState;
                    });

                    if (taskId !== "new") {
                      setEditedTaskDetails({
                        ...editedTaskDetails,
                        statusId: e.statusId,
                        swimlaneId: e.swimlaneId,
                      });
                      // await dispatch(
                      //   updateGoal({
                      //     ...e,
                      //     goalId: taskId,
                      //     action: {
                      //       oldValue: selectedTask.assigneeId,
                      //       newValue: e.swimlaneId,
                      //       actionName: TASK_ACTIVITY_LOG_ACTIONS.STATUS_CHANGED,
                      //     },
                      //   })
                      // );
                      await dispatch(
                        updateSchedule({ key: "isGoalEdited", value: true })
                      );
                      // dispatch(getTaskActivityLog({ goalId: taskId }));
                      if (e.statusId == 3) {
                        dispatch(
                          updateProgress({
                            projectId,
                          })
                        );
                      }
                    }
                  }
                  // queryClient.invalidateQueries(
                  //   ["goalsBySprint", projectId, taskDetails.sprintId],
                  //   {
                  //     refetchInactive: true,
                  //   }
                  // );
                }}
                statusId={taskDetails.statusId}
                task={taskDetails}
              />
              {taskId !== "new" ? (
                <Dropdown
                  dropdownRender={(menu) => (
                    <div className="w-[200px]">{menu}</div>
                  )}
                  trigger={["click"]}
                  menu={{ items: groupItems, onClick }}
                >
                  <button>
                    <DotsVerticalIcon className="text-gray-900" />
                  </button>
                </Dropdown>
              ) : null}
            </div>
            {isWaddleIt && (
              <>
                <RowLayout
                  customLeft={<div className="mt-1">Approvers</div>}
                  className="mt-5 mb-5 "
                  style={{ alignItems: "flex-start" }}
                >
                  <AddApprovers
                    task={taskDetails}
                    selectedUsers={waddleItUsers}
                    setSelectedUsers={setWaddleItUsers}
                    waddleItData={selectedTask?.waddleIt || []}
                    onRemoveUser={async (id) => {
                      if (
                        taskId !== "new" &&
                        selectedTask?.assigneeId == user.id
                      ) {
                        await client.put("/schedule/remove-waddle-it-user", {
                          userId: id,
                          goalId: taskId,
                        });
                      }
                    }}
                    onAddUser={async (id) => {
                      if (
                        taskId !== "new" &&
                        selectedTask?.assigneeId == user.id
                      ) {
                        await client.post("/schedule/add-users-in-waddle-it", {
                          userId: id,
                          goalId: taskId,
                          projectId,
                        });
                      }
                    }}
                  />
                </RowLayout>
                <RowLayout
                  customLeft={
                    <div className="flex items-center gap-x-1">
                      <span> Due Date</span>{" "}
                      <InfoByFrosty
                        title=" Due Date"
                        content={
                          "Adding a due date ensures prompt action from the approvers to move this task forward."
                        }
                      />
                    </div>
                  }
                  className="mt-5 mb-5"
                >
                  <DueDate
                    task={taskDetails}
                    setTask={async (e) => {
                      setTaskDetails({ ...taskDetails, dueDate: e });
                      if (taskId !== "new") {
                        setEditedTaskDetails({
                          ...editedTaskDetails,
                          dueDate: e,
                        });
                        // await dispatch(
                        //   updateGoal({
                        //     dueDate: e,
                        //     goalId: taskId,
                        //   })
                        // );
                        await dispatch(
                          updateSchedule({ key: "isGoalEdited", value: true })
                        );
                      }
                      // queryClient.resetQueries(
                      //   ["goalsBySprint", projectId, +taskDetails.sprintId],
                      //   { inactive: true }
                      // );
                    }}
                  />
                </RowLayout>
              </>
            )}

            <RowLayout customLeft="Assignee" className="mt-5 mb-5">
              <UserSelect
                disabled={!hasEditAccess}
                placeholder="Select Assignee"
                type="assignee"
                task={taskDetails}
                setTask={setTaskDetails}
                onEdit={async (id) => {
                  if (taskId !== "new") {
                    // console.log(taskType, "taskType");
                    if (+taskType === 3) {
                      setEditedTaskDetails({
                        ...editedTaskDetails,
                        assigneeId: id,
                      });
                    } else {
                      dispatch(
                        updateSchedule({
                          key: "onAssigneeChange",
                          value: {
                            visible: true,
                            originalAssignee: taskDetails?.assigneeId,
                            updatedAssignee: id,
                          },
                        })
                      );
                    }
                    // console.log(editedTaskDetails, "editedTaskDetails");

                    // await dispatch(
                    //   updateGoal({
                    //     assigneeId: id,
                    //     goalId: taskId,
                    //     action: {
                    //       oldValue: selectedTask.assigneeId,
                    //       newValue: id,
                    //       actionName: TASK_ACTIVITY_LOG_ACTIONS.ASSIGNEE_CHANGED,
                    //     },
                    //   })
                    // );
                    // await dispatch(
                    //   updateSchedule({ key: "isGoalEdited", value: true })
                    // );

                    // dispatch(getGoalDetails({ goalId: taskId }));
                    // dispatch(getTaskActivityLog({ goalId: taskId }));
                    // updateSprintGoals(taskDetails);
                  }
                  // queryClient.resetQueries(
                  //   ["goalsBySprint", projectId, +taskDetails.sprintId],
                  //   { inactive: true }
                  // );

                  // updateSprintGoals(taskDetails);
                }}
              />
            </RowLayout>
            <RowLayout customLeft="Reporter" className=" mb-5">
              <UserSelect
                disabled={!REPORTER_EDIT}
                placeholder="Select Reporter"
                type="reporter"
                task={taskDetails}
                setTask={setTaskDetails}
                onEdit={async (id) => {
                  if (taskId !== "new") {
                    setEditedTaskDetails({
                      ...editedTaskDetails,
                      reporterId: id,
                    });
                    // await dispatch(
                    //   updateGoal({
                    //     reporterId: id,
                    //     goalId: taskId,
                    //     action: {
                    //       oldValue: selectedTask.reporterId,
                    //       newValue: id,
                    //       actionName: TASK_ACTIVITY_LOG_ACTIONS.REPORTER_CHANGED,
                    //     },
                    //   })
                    // );
                    // await dispatch(
                    //   updateSchedule({ key: "isGoalEdited", value: true })
                    // );
                    // dispatch(getGoalDetails({ goalId: taskId }));

                    // dispatch(getTaskActivityLog({ goalId: taskId }));
                  }

                  // queryClient.resetQueries(
                  //   ["goalsBySprint", projectId, +taskDetails.sprintId],
                  //   { inactive: true }
                  // );

                  // updateSprintGoals(taskDetails);
                }}
              />
            </RowLayout>
            {+taskType !== 3 && (
              <RowLayout customLeft="Collaborators" className=" mb-5">
                <ScheduleCollaboratorSelect task={taskDetails} />

                {/* <CollaboratorSelect
                  disabled={!REPORTER_EDIT}
                  placeholder={
                    <div className="flex gap-x-2.5 items-center justify-center">
                      <PlusIcon className="text-gray-500" />
                      <p className="text-gray-500 out-300-14">
                        Add collaborators
                      </p>
                    </div>
                  }
                  type="reporter"
                  task={taskDetails}
                  setTask={setTaskDetails}
                  onEdit={async (id) => {
                    if (taskId !== "new") {
                      await dispatch(
                        updateGoal({
                          reporterId: id,
                          goalId: taskId,
                          action: {
                            oldValue: currentSelectedTask.reporterId,
                            newValue: id,
                            actionName:
                              TASK_ACTIVITY_LOG_ACTIONS.REPORTER_CHANGED,
                          },
                        })
                      );
                      await dispatch(
                        updateSchedule({ key: "isGoalEdited", value: true })
                      );
                      dispatch(getGoalDetails({ goalId: taskId }));

                      dispatch(getTaskActivityLog({ goalId: taskId }));
                    }
                    const sprintId = parseInt(taskDetails.sprintId, 10);
                    queryClient.resetQueries(
                      ["goalsBySprint", projectId, sprintId],
                      { inactive: true }
                    );

                    updateSprintGoals(taskDetails);
                  }}
                /> */}
              </RowLayout>
            )}

            {!projectLoading && projectDetails?.projectDetails?.hasPhases && (
              <RowLayout customLeft="Phase" className=" mb-5">
                <PhaseSelect
                  disabled={!hasEditAccess}
                  task={taskDetails}
                  setTask={setTaskDetails}
                  onEdit={async (e) => {
                    if (taskId !== "new") {
                      setEditedTaskDetails({
                        ...editedTaskDetails,
                        phaseId: e,
                      });
                      // await dispatch(
                      //   updateGoal({
                      //     phaseId: e,
                      //     goalId: taskId,
                      //     action: {
                      //       oldValue: selectedTask.phaseId,
                      //       newValue: e,
                      //       actionName: TASK_ACTIVITY_LOG_ACTIONS.PHASE_CHANGED,
                      //     },
                      //   })
                      // );
                      // await dispatch(
                      //   updateSchedule({ key: "isGoalEdited", value: true })
                      // );
                      // dispatch(getTaskActivityLog({ goalId: taskId }));
                    }

                    // queryClient.resetQueries(
                    //   ["goalsBySprint", projectId, +taskDetails.sprintId],
                    //   { inactive: true }
                    // );
                  }}
                />
              </RowLayout>
            )}

            <RowLayout customLeft="Priority" className=" mb-5">
              <PrioritySelect
                disabled={!hasEditAccess}
                task={taskDetails}
                setTask={async (e) => {
                  setTaskDetails({ ...taskDetails, priorityId: e });

                  if (taskId !== "new") {
                    setEditedTaskDetails({
                      ...editedTaskDetails,
                      priorityId: e,
                    });
                    // await dispatch(
                    //   updateGoal({
                    //     priorityId: e,
                    //     goalId: taskId,
                    //     action: {
                    //       oldValue: selectedTask.priorityId,
                    //       newValue: e,
                    //       actionName: TASK_ACTIVITY_LOG_ACTIONS.PRIORITY_CHANGE,
                    //     },
                    //   })
                    // );
                    // await dispatch(
                    //   updateSchedule({ key: "isGoalEdited", value: true })
                    // );
                    // dispatch(getTaskActivityLog({ goalId: taskId }));
                  }
                  // updateSprintGoals(taskDetails);

                  // queryClient.resetQueries(
                  //   ["goalsBySprint", projectId, +taskDetails.sprintId],
                  //   { inactive: true }
                  // );
                }}
              />
            </RowLayout>
            {+taskType !== 3 && (
              <RowLayout customLeft="Label" className=" mb-5">
                <LabelSelect
                  disabled={!hasEditAccess}
                  task={taskDetails}
                  setTask={async (e) => {
                    setTaskDetails({ ...taskDetails, labelId: e });
                    setEditedTaskDetails({
                      ...editedTaskDetails,
                      labelId: e,
                    });
                    if (taskId !== "new") {
                      // await dispatch(
                      //   updateGoal({
                      //     labelId: e,
                      //     goalId: taskId,
                      //     action: {
                      //       oldValue: selectedTask.labelId,
                      //       newValue: e,
                      //       actionName: TASK_ACTIVITY_LOG_ACTIONS.LABEL_CHANGE,
                      //     },
                      //   })
                      // );
                      // await dispatch(
                      //   updateSchedule({ key: "isGoalEdited", value: true })
                      // );
                      // dispatch(getTaskActivityLog({ goalId: taskId }));
                    }
                    // updateSprintGoals(taskDetails);

                    // queryClient.resetQueries(
                    //   ["goalsBySprint", projectId, +taskDetails.sprintId],
                    //   { inactive: true }
                    // );
                  }}
                />
              </RowLayout>
            )}

            <RowLayout customLeft="Sprint" className=" mb-5">
              <SprintSelect
                disabled={!hasEditAccess}
                task={taskDetails}
                setTask={async (e) => {
                  setTaskDetails({ ...taskDetails, sprintId: e });
                  if (taskId !== "new") {
                    setEditedTaskDetails({
                      ...editedTaskDetails,

                      sprintId: e,
                    });
                    // await dispatch(
                    //   updateGoal({
                    //     sprintId: e,
                    //     goalId: taskId,
                    //     action: {
                    //       oldValue: selectedTask.sprintId,
                    //       newValue: e,
                    //       actionName: TASK_ACTIVITY_LOG_ACTIONS.SPRINT_CHANGE,
                    //     },
                    //   })
                    // );
                    // await dispatch(
                    //   updateSchedule({ key: "isGoalEdited", value: true })
                    // );
                    // dispatch(getTaskActivityLog({ goalId: taskId }));
                  }

                  // queryClient.resetQueries(
                  //   ["goalsBySprint", projectId, +taskDetails.sprintId],
                  //   { inactive: true }
                  // );
                }}
              />
            </RowLayout>

            {!isBug && (
              <RowLayout customLeft="Start Date" className=" mb-5">
                <TaskDueDate
                  task={taskDetails}
                  type={"Start"}
                  setTask={async (e) => {
                    setTaskDetails({ ...taskDetails, startDate: e });
                    if (taskId !== "new") {
                      setEditedTaskDetails({
                        ...editedTaskDetails,

                        startDate: e,
                      });
                      // await dispatch(
                      //   updateGoal({
                      //     startDate: e,
                      //     goalId: taskId,
                      //   })
                      // );
                    }
                  }}
                />
              </RowLayout>
            )}
            {!isBug && (
              <RowLayout customLeft="End Date" className=" mb-8">
                <TaskDueDate
                  task={taskDetails}
                  type={"End"}
                  setTask={async (e) => {
                    setTaskDetails({ ...taskDetails, endDate: e });
                    if (taskId !== "new") {
                      setEditedTaskDetails({
                        ...editedTaskDetails,

                        endDate: e,
                      });
                      // await dispatch(
                      //   updateGoal({
                      //     endDate: e,
                      //     goalId: taskId,
                      //   })
                      // );
                    }
                  }}
                />
              </RowLayout>
            )}

            <div className="out-500-14 text-black">Time log</div>

            <RowLayout
              customLeft={
                <div className="flex items-center gap-x-1">
                  <span>Original estimate</span>{" "}
                  <InfoByFrosty
                    title="Original estimate"
                    content={
                      <div className="ml-12">
                        <p className="out-500-14 text-gray-500">
                          0w 0d 00h 00m
                        </p>
                        <p className="out-300-14  pt-2 text-gray-500">
                          w = weeks
                        </p>
                        <p className="out-300-14  pt-2 text-gray-500">
                          d = days
                        </p>
                        <p className="out-300-14  pt-2 text-gray-500">
                          h = hours
                        </p>
                        <p className="out-300-14  pt-2 text-gray-500">
                          m = minutes
                        </p>
                      </div>
                    }
                  />
                </div>
              }
              className="mt-4 mb-5"
            >
              <TimeInput
                className=""
                disabled={!hasEditAccess}
                value={taskDetails.estimatedTime}
                onChange={(e) =>
                  setTaskDetails({ ...taskDetails, estimatedTime: e })
                }
                onOk={async (newTime: string) => {
                  if (taskId !== "new") {
                    setEditedTaskDetails({
                      ...editedTaskDetails,

                      estimatedTime: newTime,
                    });
                    // await dispatch(
                    //   updateGoal({
                    //     estimatedTime: newTime,
                    //     goalId: taskId,
                    //     action: {
                    //       oldValue: selectedTask.estimatedTime,
                    //       newValue: newTime,
                    //       actionName: TASK_ACTIVITY_LOG_ACTIONS.ESTIMATE_CHANGE,
                    //     },
                    //   })
                    // );
                    // await dispatch(
                    //   updateSchedule({ key: "isGoalEdited", value: true })
                    // );
                    // dispatch(getTaskActivityLog({ goalId: taskId }));
                  }
                }}
              />
            </RowLayout>

            <RowLayout
              customLeft={
                <div className="flex items-center gap-x-1">
                  <span>Log more time</span>{" "}
                  <InfoByFrosty
                    title="Log more time"
                    content={
                      <div className="ml-12">
                        <p className="out-500-14 text-gray-500">
                          0w 0d 00h 00m
                        </p>
                        <p className="out-300-14  pt-2 text-gray-500">
                          w = weeks
                        </p>
                        <p className="out-300-14  pt-2 text-gray-500">
                          d = days
                        </p>
                        <p className="out-300-14  pt-2 text-gray-500">
                          h = hours
                        </p>
                        <p className="out-300-14  pt-2 text-gray-500">
                          m = minutes
                        </p>
                      </div>
                    }
                  />
                </div>
              }
              className="mt-4 mb-8"
            >
              {taskId === "new" ? (
                <Controller
                  name="timeLogged"
                  control={control}
                  // rules={{ required: "Please enter a title before submitting" }}
                  render={({ field, formState }) => (
                    <TimeInput
                      className=""
                      disabled={
                        taskDetails.timeLogged === ""
                          ? !LOG_TIME_ADD
                          : !LOG_TIME_EDIT
                      }
                      value={
                        taskDetails.timeLogged || getValues("timeLogged") || ""
                      }
                      addNewTimeToPreviousTime
                      onChange={(e) =>
                        setTaskDetails({ ...taskDetails, timeLogged: e })
                      }
                      onOk={async (newTime: string, totalTime: string) => {
                        if (taskId !== "new") {
                          setEditedTaskDetails({
                            ...editedTaskDetails,

                            timeLogged: newTime,
                          });
                          // await dispatch(
                          //   updateGoal({
                          //     timeLogged: newTime,
                          //     goalId: taskId,
                          //     action: {
                          //       oldValue: taskDetails.timeLogged,
                          //       newValue: totalTime,
                          //       actionName:
                          //         TASK_ACTIVITY_LOG_ACTIONS.TIMELOG_CHANGE,
                          //     },
                          //   })
                          // );
                          // await dispatch(
                          //   updateSchedule({ key: "isGoalEdited", value: true })
                          // );
                          // dispatch(getGoalDetails({ goalId: taskId }));
                          // dispatch(getTaskActivityLog({ goalId: taskId }));
                        }
                      }}
                    />
                  )}
                />
              ) : (
                <TimeInput
                  className=""
                  disabled={
                    taskDetails.timeLogged === ""
                      ? !LOG_TIME_ADD
                      : !LOG_TIME_EDIT
                  }
                  value={taskDetails.timeLogged || ""}
                  addNewTimeToPreviousTime
                  onChange={(e) =>
                    setTaskDetails({ ...taskDetails, timeLogged: e })
                  }
                  onOk={async (newTime: string, totalTime: string) => {
                    if (taskId !== "new") {
                      setEditedTaskDetails({
                        ...editedTaskDetails,

                        timeLogged: newTime,
                      });
                      // await dispatch(
                      //   updateGoal({
                      //     timeLogged: newTime,
                      //     goalId: taskId,
                      //     action: {
                      //       oldValue: taskDetails.timeLogged,
                      //       newValue: totalTime,
                      //       actionName: TASK_ACTIVITY_LOG_ACTIONS.TIMELOG_CHANGE,
                      //     },
                      //   })
                      // );
                      // await dispatch(
                      //   updateSchedule({ key: "isGoalEdited", value: true })
                      // );
                      // dispatch(getGoalDetails({ goalId: taskId }));
                      // dispatch(getTaskActivityLog({ goalId: taskId }));
                    }
                  }}
                />
              )}
            </RowLayout>

            <div className="out-500-14 text-black">More details</div>
            <RowLayout
              customLeft={
                <div className="flex items-center gap-x-1">
                  <span>Release version</span>{" "}
                  <InfoByFrosty
                    title="Release version"
                    content={
                      "Release versions are important for identifying and tracking different versions of the software. They help with testing, debugging, troubleshooting, and enable users to understand which version of the software they are using, along with its features and improvements."
                    }
                  />
                </div>
              }
              className="mt-4 mb-8"
            >
              <VersionSelect
                task={taskDetails}
                disabled={!hasEditAccess}
                setTask={async (e) => {
                  setTaskDetails({ ...taskDetails, releaseVersionId: e });
                  if (taskId !== "new") {
                    setEditedTaskDetails({
                      ...editedTaskDetails,

                      releaseVersionId: e,
                    });
                    // await dispatch(
                    //   updateGoal({
                    //     releaseVersionId: e,
                    //     goalId: taskId,
                    //     action: {
                    //       oldValue: selectedTask.releaseVersionId,
                    //       newValue: e,
                    //       actionName:
                    //         TASK_ACTIVITY_LOG_ACTIONS.RELEASE_VERSION_CHANGE,
                    //     },
                    //   })
                    // );
                    // await dispatch(
                    //   updateSchedule({ key: "isGoalEdited", value: true })
                    // );
                    // dispatch(getTaskActivityLog({ goalId: taskId }));
                  }

                  // queryClient.resetQueries(
                  //   ["goalsBySprint", projectId, +taskDetails.sprintId],
                  //   { inactive: true }
                  // );
                }}
              />
            </RowLayout>
            <RowLayout customLeft="Created on" className=" mb-5">
              <div className="flex px-[14px]">
                <p className="out-300-14 text-gray-400  ">
                  {taskDetails?.createdAt
                    ? dayjs(taskDetails?.createdAt).format("DD/MM/YYYY")
                    : dayjs().format("DD/MM/YYYY")}
                </p>
              </div>
            </RowLayout>

            {completedOn && (
              <RowLayout customLeft="Completed on" className="mt-4 mb-5">
                <div className="flex px-[14px]">
                  <p className="out-300-14 text-gray-400  ">{completedOn}</p>
                </div>
              </RowLayout>
            )}
          </div>
        </div>
      </Spin>

      <SubtaskToDoneModal
        visible={subtasksInProgressModal}
        onClose={RevertStatus}
        onSave={SaveSubtaskEdit}
        statusIndicator={statusIndicator}
      />

      <NewLabelModal
        visible={newLabelModal}
        onClose={() => dispatch(resetSchedule(["newLabelModal"]))}
      />

      <ManageLabelsModal
        visible={manageLabelModal}
        onClose={() => dispatch(resetSchedule(["manageLabelModal"]))}
      />
      <DeleteLabelModal
        visible={deleteLabelModal.visible}
        onClose={() => dispatch(resetSchedule(["deleteLabelModal"]))}
      />
      <NewReleaseVersionModal
        visible={newReleaseVersionModal}
        onClose={() => dispatch(resetSchedule(["newReleaseVersionModal"]))}
      />
      <DeleteTaskModal
        visible={taskDeleteModal}
        selectedTasks={selectedTasks}
        onClose={() => setTaskDeleteModal(false)}
      />

      <FilePreviweModal
        visible={filePreview.visible}
        page="task"
        onClose={() => dispatch(resetConversations(["filePreview"]))}
      />
      <DiscardEditedGoalModal
        onClose={() => setDiscardModal(false)}
        onDiscard={() => {
          setEditedTaskDetails(false);
          setDiscardModal(false);
          onDiscardTask();
        }}
        visible={discardModal}
      />
      <ChangeAssigneeModal
        visible={onAssigneeChange?.visible}
        onClose={() =>
          dispatch(
            updateSchedule({
              key: "onAssigneeChange",
              value: { ...onAssigneeChange, visible: false },
            })
          )
        }
        onYes={handleYes}
        onNo={handleNo}
      />
    </ScheduleLayout>
  );
};

export default TaskScreen;

import React, { useCallback, useEffect, useState } from "react";
import { Divider, Layout } from "antd";
import {
  DashBoardIcon,
  LibraryIcon,
  MessagesIcon,
  ScheduleIcon,
} from "./LayoutIcons";
import { ChevronDoubleIcon, ChevronIcon } from "../svgV2/Chevron";
import CustomAvatar from "../Common/CustomAvatar";
import { PlusIcon } from "../svgV2/PlusIcon";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

import { getProjectPhases } from "../redux/api/scheduleAPI";
import {
  getProjectDetatis,
  getProjectList,
  getProjectPermission,
} from "../redux/api/projectAPI";
import { getDefaultNavigation } from "../redux/api/settingsAPI";
import { getScreenName } from "../utils/scheduleUtils";
import CustomSearchBox from "../CommonV2/CustomSearchBox";
import { getTenantDetails } from "../redux/api/tenantAPI";
import { changeTenant } from "../redux/slices/tenant.slice";
import { changeWorkspace } from "../redux/api/dashboardAPI";
import { resetToInitialConversation } from "../redux/slices/conversations.slice";
import ClipBoardIcon from "../svgV3/ClipBoardIcon";
import { useGetTenantDetails } from "../redux/ReactQueryHooksV3/useTenantAPI";
import { useQueryClient } from "react-query";
import {
  useGetProjectDetails,
  useGetProjectList,
} from "../redux/ReactQueryHooksV3/useProjectAPI";
import { useGetProjectPhases } from "../redux/ReactQueryHooksV3/useScheduleAPI";
import { MyCalendarIcon } from "../svgV3/MyCalendarIcon";
import { DCFIcon } from "../svgV3/DCFIcon";
import { updateFootprint } from "../redux/slices/footprint.slice";

const MenuItemCollapse = ({
  title,
  icon: Icon,
  isSelected = false,
  collapsed,
  submenu = [],
  viewAll = "/library",
  onClick = null,
  redirectionLink = null,
  onContextMenu = null,
  id,
}) => {
  const [isActive, setIsActive] = useState(false);

  const [search, setSearch] = useState("");
  const { projectId } = useParams();
  const navigate = useNavigate();

  return onClick ? (
    <a
      href={redirectionLink}
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <div id={id}>
        <div
          className={`flex group cursor-pointer rounded-lg  justify-between mt-[12px]   py-[5px] ${
            (isSelected || isActive) && "bg-primary-50"
          } ${collapsed ? "px-[7px]" : "px-5"}`}
          onClick={() => {
            setIsActive(!isActive);
            if (onClick && typeof onClick === "function") {
              onClick();
            }
          }}
        >
          <div className="flex justify-center">
            <Icon
              className={`group-hover:text-primary-600 text-[20px] text-black ${
                isSelected ? "text-primary-700" : "text-black"
              }`}
            />
            {!collapsed && (
              <span
                className={`out-500-14 group-hover:text-primary-600 ${
                  title === "My Footprint" ? "ml-[8px]" : "ml-[11px]"
                } ${isSelected ? "text-primary-700" : "text-black"}`}
              >
                {title}
              </span>
            )}
          </div>

          {!collapsed && submenu.length > 0 && (
            <ChevronIcon
              className="cursor-pointer group-hover:text-primary-600"
              style={{
                transition: "transform 0.3s ease-in-out 0s",
                transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          )}
        </div>
        {isActive && submenu.length > 0 && !collapsed && (
          <>
            <CustomSearchBox
              placeholder="Search Projects"
              className="mt-3"
              search={search}
              setSearch={setSearch}
            />
            <div className="mt-[12px]  ml-[32px]  border-l border-gray-300">
              <ul className="flex flex-col gap-y-5">
                {submenu
                  .filter((p) =>
                    p.label?.toLowerCase().includes(search.toLowerCase())
                  )
                  .slice(0, 3)
                  .map((menu, index) => (
                    <>
                      {console.log(menu, "menu")}
                      <li
                        key={index}
                        className={`pl-4 cursor-pointer  hover:text-primary-700 out-300-14 ${
                          menu.link?.includes(projectId)
                            ? "text-primary-700"
                            : "text-black "
                        }`}
                      >
                        <Link to={menu.link}> {menu.label} </Link>
                      </li>
                    </>
                  ))}
              </ul>
            </div>
            <div className="ml-[48px] cursor-pointer out-500-14 text-primary-600 pt-[18px]">
              <Link to={viewAll}> View all </Link>
            </div>
          </>
        )}
      </div>
    </a>
  ) : (
    <div id={id}>
      <div
        className={`flex group cursor-pointer rounded-lg  justify-between mt-[12px]   py-[5px] ${
          (isSelected || isActive) && "bg-primary-50"
        } ${collapsed ? "px-[7px]" : "px-5"}`}
        onClick={() => {
          setIsActive(!isActive);
          if (onClick && typeof onClick === "function") {
            onClick();
          }
        }}
      >
        <div className="flex justify-center">
          <Icon
            className={`group-hover:text-primary-600 text-[20px] text-black ${
              isSelected ? "text-primary-700" : "text-black"
            }`}
          />
          {!collapsed && (
            <span
              className={`out-500-14 group-hover:text-primary-600 ml-[11px]  ${
                isSelected ? "text-primary-700" : "text-black"
              }`}
            >
              {title}
            </span>
          )}
        </div>

        {!collapsed && submenu.length > 0 && (
          <ChevronIcon
            className="cursor-pointer group-hover:text-primary-600"
            style={{
              transition: "transform 0.3s ease-in-out 0s",
              transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        )}
      </div>
      {isActive && submenu.length > 0 && !collapsed && (
        <>
          <CustomSearchBox
            placeholder="Search Projects"
            className="mt-3"
            search={search}
            setSearch={setSearch}
          />
          <div className="mt-[12px]  ml-[32px]  border-l border-gray-300">
            <ul className="flex flex-col gap-y-5">
              {submenu
                .filter((p) =>
                  p.label?.toLowerCase().includes(search.toLowerCase())
                )
                .slice(0, 3)
                .map((menu, index) => (
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(menu.link);
                    }}
                    key={index}
                  >
                    <li
                      key={index}
                      className={`pl-4 cursor-pointer  hover:text-primary-700 out-300-14 ${
                        menu.link?.includes(projectId)
                          ? "text-primary-700"
                          : "text-black "
                      }`}
                    >
                      <Link to={menu.link}> {menu.label} </Link>
                    </li>
                  </a>
                ))}
            </ul>
          </div>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigate(viewAll);
            }}
          >
            <div className="ml-[48px] cursor-pointer out-500-14 text-primary-600 pt-[18px]">
              <Link to={viewAll}> View all </Link>
            </div>
          </a>
        </>
      )}
    </div>
  );
};

const WorkSpaceSelect = ({ collapsed }) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { currentTenantId } = useAppSelector((state) => state.tenant);
  const { user } = useAppSelector((state) => state.userDetails);

  const [isActive, setIsActive] = useState(false);
  const { workspaces } = useAppSelector((state) => state.dashboard);

  const hostname = window.location.host;

  const antarcticaTenantId = "3fb2a4e6-f736-4209-aceb-05e77d19b808"; // currently we are restricting some features for antarctica global tenant's users

  const navigate = useNavigate();

  const { data: Data, isLoading, isError } = useGetTenantDetails();
  const tenantDetails = Data?.result;

  const handleSwitchWorkSpace = async (tenantId) => {
    let isCurrentScreenMessages = false;
    if (window.location.pathname == "/messages") {
      isCurrentScreenMessages = true;
    }
    dispatch(changeTenant(tenantId));
    await dispatch(changeWorkspace({ tenantId }));
    dispatch(getTenantDetails({}));
    setTimeout(() => {
      dispatch(resetToInitialConversation({}));
      //location.reload();
      // navigate("/dashboard");

      // if the current screen is messages , redirect the user back to messages screen after switching tenant
      isCurrentScreenMessages
        ? (window.location.href = "/messages")
        : (window.location.href = "/dashboard");
    }, 1500);
  };

  return (
    <div
      onClick={() => setIsActive(!isActive)}
      role="button"
      className={` group cursor-pointer rounded-xl mx-2  justify-between mt-[12px]    py-[14px] ${
        !collapsed && "bg-primary-50 border-primary-300 border"
      } `}
    >
      {isActive && !isLoading && !collapsed && (
        <>
          {(hostname !== "localhost:3000" &&
            process.env.REACT_APP_ENV &&
            process.env.REACT_APP_ENV !== "qa" &&
            process.env.REACT_APP_ENV !== "develop" &&
            tenantDetails?.tenantId !== antarcticaTenantId) ||
          +user.roleId === 4 ? (
            <div className="text-primary-600 out-500-12 mt-[8px] flex items-center ml-[18px]">
              <ChevronIcon className="text-primary-600" />{" "}
              <span className="ml-2">Select your Workspace</span>
            </div>
          ) : (
            <div
              role="button"
              onClick={() => navigate("/onboardingV2")}
              className="text-primary-600 out-500-12 mt-[8px] flex items-center ml-[18px]"
            >
              <PlusIcon className="text-primary-600" />{" "}
              <span className="ml-2">Create new</span>
            </div>
          )}

          <div className="mt-[12px] mb-6  ml-[28px]  border-l border-gray-300">
            <ul className="max-h-[20vh] overflow-y-auto">
              {workspaces?.map((menu, index) => (
                <li
                  key={index}
                  role="button"
                  onClick={() => handleSwitchWorkSpace(menu.tenantId)}
                  className="text-black pl-4 cursor-pointer mt-[18px] truncate first:mt-0 hover:text-primary-600 out-300-14"
                >
                  {menu.tenantName}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
      <div
        className={`flex justify-between w-full ${
          collapsed ? "px-[7px]" : "px-4"
        }`}
      >
        <div className="flex items-center justify-center ">
          <CustomAvatar title={tenantDetails?.tenantName} size={20} />
          {!collapsed && (
            <span className="out-500-14 group-hover:text-primary-600 ml-1 max-h-6 max-w-[88px] truncate  text-primary-600">
              {tenantDetails?.tenantName}
            </span>
          )}
        </div>
        {!collapsed && (
          <div>
            <ChevronIcon
              className="cursor-pointer group-hover:text-primary-600"
              style={{
                transition: "transform 0.3s ease-in-out 0s",
                transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const { Sider } = Layout;

const SideBar = ({ page }) => {
  const { user } = useAppSelector((state) => state.userDetails);
  const { currentTenantId } = useAppSelector((state) => state.tenant);
  const dispatch = useAppDispatch();
  const [collapsed, setCollaosed] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { defaultNavigation } = useAppSelector((state) => state.settings);
  const { lastVisitedSchedulePage } = useAppSelector((state) => state.schedule);

  const [searchParams] = useSearchParams();

  const { data: projectList, isLoading: isLoadingProjectList } =
    useGetProjectList(user.id);
  const {
    data: projectDetails,
    isLoading: isLoadingProjectDetails,
    error: projectDetailsError,
  } = useGetProjectDetails(projectId || searchParams.get("projectId"));
  const {
    data: projectPhases,
    isLoading: isLoadingProjectPhases,
    error: projectPhasesError,
  } = useGetProjectPhases(projectId || searchParams.get("projectId"));

  useEffect(() => {
    dispatch(getDefaultNavigation({ userId: user.id }));
  }, [user.id]);

  useEffect(() => {
    if (projectId) {
      dispatch(
        getProjectPermission({
          projectId,
          userId: user.id,
        })
      );
    }
  }, [projectId, user.id]);

  const handleToggle = () => {
    // case 1 if isSideBarOpen === true then mouseEventShoun't work and handleToggle should set isSideOPen to false
    // case 2 if isSideBarOpen === false the mouseEvent should work and handleToggle should set isSideBarOpen to true
    if (isSideBarOpen) {
      setIsSideBarOpen(false);
      setCollaosed(true);
    } else {
      setIsSideBarOpen(true);
      setCollaosed(false);
    }
  };

  let librarySubmenu = [
    {
      label: "My Library",
      link: `/library/my-library/lib-${user.myLibraryId}`,
    },
  ];
  if (projectList?.length > 0) {
    librarySubmenu = [
      {
        label: "My Library",
        link: `/library/my-library/lib-${user.myLibraryId}`,
      },
      ...projectList.map((project) => ({
        label: project.title,
        link: `/library/${project.id}`,
      })),
    ];
  }
  if (+user.roleId === 4) {
    if (projectList.length <= 0) {
      librarySubmenu = [];
    } else {
      librarySubmenu = [
        ...projectList.map((project) => ({
          label: project.title,
          link: `/library/${project.id}`,
        })),
      ];
    }
  }

  const getScheduleSubmenu = useCallback(() => {
    let scheduleSubmenu = [];
    if (projectList?.length > 0) {
      let screenName = "roadmap";
      if (defaultNavigation.isEnabled) {
        screenName = getScreenName(defaultNavigation.screenId);
      }
      scheduleSubmenu = projectList.map((project) => ({
        label: project.title,
        link:
          +lastVisitedSchedulePage?.projectId === +project.id
            ? lastVisitedSchedulePage?.redirectUrl
            : project.currentactivesprint
            ? `/schedule/${project.id}/${screenName}/${
                screenName == "sprints" ? project.currentactivesprint : ""
              }`
            : `/schedule/${project.id}/${screenName}`,
      }));
    }
    return scheduleSubmenu;
  }, [projectList, defaultNavigation]);

  return (
    <>
      {collapsed && <div className="h-screen w-[50px] " />}
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth={50}
        style={{
          position: isSideBarOpen ? "relative" : "fixed",
          height: "100vh",
          zIndex: 2,
          //  transition: " max-width 2s",
        }}
      >
        <div
          className="h-full bg-white border-r border-gray-200"
          onMouseEnter={() => !isSideBarOpen && setCollaosed(false)}
          onMouseLeave={() => !isSideBarOpen && setCollaosed(true)}
        >
          <div className="flex flex-col justify-between h-full">
            <div>
              <div
                className={`flex items-center justify-between ${
                  collapsed ? "px-[13px]" : "px-6"
                } pt-[18px]`}
              >
                <img
                  className=""
                  alt="logo"
                  src={
                    collapsed
                      ? "/images/v2/layout/w.svg"
                      : "/images/v2/layout/Logo.svg"
                  }
                />

                <button
                  className={`${collapsed ? "hidden" : "block"}`}
                  onClick={() => handleToggle()}
                >
                  <ChevronDoubleIcon
                    rotate={90}
                    style={{
                      transition: "transform 0.3s ease-in-out 0s",
                      transform: !isSideBarOpen
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    }}
                  />
                </button>
              </div>
              <div className="px-2">
                <MenuItemCollapse
                  title={"Dashboard"}
                  icon={DashBoardIcon}
                  collapsed={collapsed}
                  isSelected={page === "dashboard"}
                  redirectionLink={`/dashboard/${projectId ? projectId : ""}`}
                  onContextMenu={() =>
                    window.open(
                      `/dashboard/${projectId ? projectId : ""}`,
                      "_blank"
                    )
                  }
                  onClick={() => {
                    navigate(`/dashboard/${projectId ? projectId : ""}`);
                  }}
                  id="dashboard-tour-dashboard"
                />
                {user.roleId !== 4 && !user.isGuestDeactivated && (
                  <MenuItemCollapse
                    title={"Messages"}
                    icon={MessagesIcon}
                    isSelected={page === "messages"}
                    redirectionLink={"/messages"}
                    collapsed={collapsed}
                    onContextMenu={() => window.open("/messages", "_blank")}
                    onClick={() => navigate("/messages")}
                    id="dashboard-tour-messages"
                  />
                )}
                {user.roleId !== 4 && !user.isGuestDeactivated && (
                  <MenuItemCollapse
                    title={"Projects"}
                    icon={ScheduleIcon}
                    collapsed={collapsed}
                    isSelected={page === "schedule"}
                    submenu={getScheduleSubmenu()}
                    viewAll="/dashboard?showAllProjects=1"
                    id="dashboard-tour-schedule"
                  />
                )}

                <MenuItemCollapse
                  title={"Library"}
                  icon={LibraryIcon}
                  isSelected={page === "library"}
                  collapsed={collapsed}
                  submenu={librarySubmenu}
                  viewAll="/library"
                  id="dashboard-tour-library"
                />
                <Divider className="mt-2 mb-0" />

                <MenuItemCollapse
                  title={"My Board"}
                  icon={ClipBoardIcon}
                  isSelected={page === "my-board"}
                  redirectionLink={"/my-board"}
                  collapsed={collapsed}
                  onContextMenu={() => window.open("/my-board", "_blank")}
                  onClick={() => navigate("/my-board")}
                  id="dashboard-tour-my-board"
                />

                <MenuItemCollapse
                  title={"My Calendar"}
                  icon={MyCalendarIcon}
                  isSelected={page === "my-calendar"}
                  redirectionLink={"/my-board"}
                  collapsed={collapsed}
                  onContextMenu={() => window.open("/my-calendar", "_blank")}
                  onClick={() => navigate("/my-calendar")}
                  id="dashboard-tour-my-calendar"
                />

                <MenuItemCollapse
                  title={"My Footprint"}
                  icon={DCFIcon}
                  isSelected={page === "my-footprint"}
                  redirectionLink={"/my-footprint"}
                  collapsed={collapsed}
                  onContextMenu={() => window.open("/my-footprint", "_blank")}
                  onClick={() => {
                    dispatch(
                      updateFootprint({ key: "welcomeModal", value: true })
                    );
                    navigate("/my-footprint");
                  }}
                  id="dashboard-tour-my-footprint"
                />
              </div>
            </div>
            <div className="mb-2 " id="dashboard-tour-workspace">
              <WorkSpaceSelect collapsed={collapsed} />
            </div>
          </div>
        </div>
      </Sider>
    </>
  );
};

export default SideBar;

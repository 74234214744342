import { createSlice, current } from "@reduxjs/toolkit";
import { getAllTourStatus, getWorkspaces } from "../api/dashboardAPI";
import {
  getCompletedProjectCount,
  getMyTasks,
  getOngoingProjectCount,
  getTodos,
} from "../api/dashboardAPI";
import { markSpoc } from "../api/projectAPI";
import { updateEvent } from "../api/scheduleAPI";
import dayjs from "dayjs";

const initialState = {
  loading: false,
  error: {},
  onGoingProjects: 0,
  completedProjects: 0,
  workspaces: [],
  todoList: [],
  myTasks: [],

  //V2
  allProjectView: false,
  whiteBoardModal: false,
  checkListScreen: false,
  search: "",
  errorMessageModal: { visible: false, type: "project" },
  releaseErrorModal: { visible: false, type: "project" },
  completeProjectModal: {
    visible: false,
    edit: false,
    action: "start",
    projectId: " ",
  },
  discardProjectModal: { visible: false, type: "discard", title: " ", id: " " },
  alertPopupModal: {
    visible: false,
    data: { title: "", subtitle: "", description: "" },
  },
  currentDate: "",
  monthDateModal: { visible: false, selectedDate: "" },
  newEventModal: { visible: false, startTime: "", endTime: "", date: "" },
  myCalendarFilter: {
    showWaddle: true,
    showGoogle: true,
  },
  integrationModalSuccess: false,
  takeTourFromWelcomeCard: false,
  createRoleModal: false,
  dashboardWelcomeTourModal: false,
  completePhaseModal: {
    visible: false,
    edit: false,
    action: "start",
    phase: {
      phases: "Design",
      phaseId: 1,
      startDate: "",
      endDate: "",
      projectPhaseId: "",
      isNew: true,
    },
  },
  userDetailsDrawer: { visible: false, data: {} },
  completeProjectSuccessModal: false,
  messagesScreen: true,
  viewType: 1,
  filters: {
    lead: [],
    duration: [],
    status: [],
  },
  dashboardRedirected: false,
  events: [],
  myCalendarTimelineSelected: 1,
  createEventModal: {
    visible: false,
    startTime: dayjs(),
    endTime: dayjs(),
    date: dayjs(),
  },
  editEventModal: false,
  myCalendarRecurrence: false,
  customRecurrenceLine: null,
  newTimeModal: false,
  cancelEventModal: false,
  selectedEvent: {
    kind: "",
    etag: "",
    id: "",
    status: "",
    htmlLink: "",
    description: "",
    created: "",
    updated: "",
    summary: "",
    recurringEventId: "",
    reminders: {},
    recurrence: [],
    iCalUID: "",
    sequence: 1,
    hangoutLink: "",
    attendees: [],
    start: { dateTime: "", timeZone: "", date: "" },
    end: { dateTime: "", timeZone: "", date: "" },
    location: "",
    eventType: "",
    selectedColor: 0,
    organizer: {
      email: "",
      self: false,
    },
  },
  newTimeRequestModal: {
    visible: false,
    eventId: "",
    eventName: "",
    sender: "",
    senderId: "",
    newStartTime: "",
    newEndTime: "",
    optionalNotes: "",
  },
  eventInfoModal: false,
  waddleItGoals: [],
  projectThemeColor: "#165ABF",
  alreadyExistsModal: 0,
  toursPopup: {
    dashboardTour: true,
    scheduleTour: true,
    libraryTour: true,
    messageTour: true,
    footprintOnboard: true,
  },
  currentModule: "",
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    addDummyDataForDashboardScreen: (state, action) => {
      const dummyState = action.payload;
      Object.keys(dummyState).forEach((key) => (state[key] = dummyState[key]));
    },
    resetToInitialDashBoard: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
    updateDashboard: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOngoingProjectCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOngoingProjectCount.fulfilled, (state, action) => {
        state.loading = false;
        state.onGoingProjects = action.payload.count;
      })
      .addCase(getOngoingProjectCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(getAllTourStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTourStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.toursPopup = action.payload;
        console.log(action, "tours");
      })
      .addCase(getAllTourStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        console.log(action.error, "tours");
      })
      .addCase(getCompletedProjectCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompletedProjectCount.fulfilled, (state, action) => {
        state.loading = false;
        state.completedProjects = action.payload.count;
      })
      .addCase(getCompletedProjectCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(getTodos.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTodos.fulfilled, (state, action) => {
        state.loading = false;
        state.todoList = action.payload;
      })
      .addCase(getTodos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(getMyTasks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMyTasks.fulfilled, (state, action) => {
        state.loading = false;
        state.myTasks = action.payload;
      })
      .addCase(getMyTasks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(getWorkspaces.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWorkspaces.fulfilled, (state, action) => {
        state.loading = false;
        state.workspaces = action.payload;
      })
      .addCase(getWorkspaces.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(updateEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.loading = false;

        const updatedEvent = action.payload.result[0];

        // Find the index of the sprint that needs to be updated
        const eventIndex = state.events.findIndex(
          (s) => s.id == updatedEvent.id
        );
        if (eventIndex !== -1) {
          // Create a new array with the updated sprint
          state.events = [
            ...state.events.slice(0, eventIndex),
            { ...state.events[eventIndex], ...updatedEvent },
            ...state.events.slice(eventIndex + 1),
          ];
        }
      })
      .addCase(updateEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(markSpoc.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.status = "fulfilled";
        // state.userList = state.userList.map((user) => {
        //   if (+user.userId === +payload.result.userId) {
        //     return { ...user, spoc: payload.result.spoc };
        //   }
        //   return user;
        // });
        state.userDetailsDrawer = {
          ...state.userDetailsDrawer,
          data: { ...state.userDetailsDrawer.data, spoc: payload.result.spoc },
        };
        state.error = null;
      });
  },
});

export const {
  resetToInitialDashBoard,
  updateDashboard,
  addDummyDataForDashboardScreen,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
